.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8); /* Darker background for more focus */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #1f1f1f;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 600px;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-header h2 {
    margin: 0;
    color: #ffffff;
  }
  
  .close-button {
    background-color: #bb86fc;
    color: #ffffff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .close-button:hover {
    background-color: #8a63d2;
  }
  
  .members-list {
    margin-top: 20px;
  }
  
  .member-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #333;
  }
  
  .member-info {
    display: flex;
    align-items: center;
  }
  
  .member-profile-pic {
   margin-bottom: 0px;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
    border: 2px solid #0f0e17;
    position: relative;
    z-index: 1;
    transition: transform 0.2s ease-in-out; /* Smooth scaling effect */
  }
  .member-profile-pic:hover{
    transform: scale(1.1); /* Scale up on hover */
  }
  
  .member-name {
    color: #ffffff;
  }
  
  .member-stats {
    display: flex;
    align-items: center;
  }
  
  .message-count {
    margin-right: 10px;
    color: #ffffff;
  }
  
  .status-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 8px;
  }
  
  .status-indicator.online {
    background-color: #2ecc71;
    box-shadow: 0 0 0 2px rgba(46, 204, 113, 0.2);
  }
  
  .status-indicator.away {
    background-color: #95a5a6;
    box-shadow: 0 0 0 2px rgba(149, 165, 166, 0.2);
  }
  
  .status-indicator.offline {
    background-color: #e74c3c;
    box-shadow: 0 0 0 2px rgba(231, 76, 60, 0.2);
  }
  