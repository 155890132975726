/* Container Styling */
.public-profile-container {
  width: 1600px; /* Fixed width */
  margin: 0 auto; /* Center the container */
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px; /* Ensure consistent gap */
  color: #ffffff;
  background-color: #0f0e17;
  font-family: 'Inter', sans-serif;
  margin-top: 100px;
  border-radius: 15px;
}


/* Media Queries */
@media (max-width: 1540px) {
  .public-profile-container {
    width: 1300px; /* Adjust width for smaller screens */
    padding: 30px 15px;
  }
}

@media (max-width: 1224px) {
  .public-profile-container {
    width: 100%;
    padding: 20px 10px;
    margin-top: 50px;
  }

  .profile-details {
    flex-direction: column;
    align-items: center !important;
    text-align: center;
    width: 100%;
    gap: 15px;
  }

  .profile-img {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .profile-info {
    align-items: center !important;
    width: 100%;
    text-align: center;
  }

  .profile-info h1 {
    text-align: center;
    width: 100%;
    font-size: 2em;
    margin-top: 5px !important;
    margin-bottom: 5px !important; 
  }

  .profile-actions {
    width: 100%;
    justify-content: center !important;
    margin-top: 15px;
    flex-wrap: nowrap;
  }

  .languages-container-public-prof {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .public-profile-container {
    padding: 15px 5px;
    margin-top: 30px;
  }

  .profile-details {
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    gap: 15px;
  }

  .profile-img {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .profile-info {
    align-items: center;
    width: 100%;
    text-align: center;
  }

  .profile-info h1 {
    text-align: center;
    font-size: 1.8em;
  }

  .profile-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 15px;
    gap: 10px;
  }

  .friend-status-text {
    text-align: center;
    width: 100%;
  }

  /* Ensure buttons stay in a row */
  .action-button,
  .settings-button,
  .profile-back-go-back {
    flex: 0 0 auto;
  }

  /* Language cards for mobile */
  .languages-container-public-prof {
    display: grid !important; /* Force override */
    grid-template-columns: 1fr !important; /* Force single column */
    width: 100% !important; /* Override the 930px width */
    max-width: 450px;
    margin: 0 auto;
    gap: 15px;
  }

  .language-card-pp {
    width: 100%;
    max-width: none;
  }

  .profile-img img {
    width: 80px; /* Further reduce image size */
    height: 80px;
  }

  .languages-container-public-prof {
    grid-template-columns: 1fr; /* Stack the grid items vertically */
  }

  .language-item, .badge-item {
    width: 100%; /* Ensure items take full width */
  }
}

@media (max-width: 480px) {
  .public-profile-container {
    padding: 10px 5px;
    margin-top: 20px;
  }

  .profile-details {
    gap: 5px;
  }

  .profile-info p {
    font-size: 0.9em;
  }

  .back-button {
    bottom: 100px; /* Adjust position for smaller screens */
  }
}
.back-button {
  position: absolute;
  bottom: 200px;
  left: 20px;
  background-color: #6c63ff; /* Same background color as .acc-button-pp */
  color: #ffffff; /* White text color */
  border: 2px solid #6c63ff; /* Add border matching .acc-button-pp */
  border-radius: 50%; /* Keep the rounded shape */
  font-size: 1.5em;
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 10px 20px rgba(108, 99, 255, 0.2); /* Add the same box shadow as .acc-button-pp */
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s, border-color 0.3s;
}

.back-button:hover {
  background-color: rgba(15, 14, 23, 0.8); /* Hover effect matching .acc-button-pp */
  color: #ffffff;
  border-color: #ffffff;
  box-shadow: 0 15px 30px rgba(15, 14, 23, 0.4);
  transform: translateY(-3px);
}

.back-button:active {
  transform: translateY(0);
  box-shadow: 0 10px 20px rgba(15, 14, 23, 0.2);
}
/* Profile Details Styling */
.profile-details {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #333;
}

.profile-img img {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 3px solid #4a47a3;
}

/* Languages and Badges Container Styling */
.languages-container-public-prof {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns per row */
  row-gap: 30px;
  align-items: flex-start;
  width: 930px; /* Ensure the grid takes full width */
  justify-items: center; /* Center the items within the grid */
}

.public-profile-badges-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start (left) */
}

.PP-Languages-Header {
  margin-bottom: 0px;
}

.public-profile-badges-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start; /* Align grid items to the left */
}

.public-profile-badge-card {
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 120px;
  height: 120px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.public-profile-badge-card:hover {
  transform: scale(1.1);
}

.public-profile-badge-card.locked {
  opacity: 0.5;
  background-color: rgba(27, 27, 47, 0.8);
}

.public-profile-badge-image {
  width: 70%;
  height: 70%;
  object-fit: contain;
}

/* Specific Badge Styles */
.public-profile-badge-card.vibranium {
  background: linear-gradient(135deg, rgba(75, 0, 130, 0.5) 25%, rgba(0, 0, 0, 0.5) 25%, rgba(75, 0, 130, 0.5) 50%, rgba(0, 0, 0, 0.5) 50%, rgba(75, 0, 130, 0.5) 75%, rgba(0, 0, 0, 0.5) 75%, rgba(75, 0, 130, 0.5));
  background-size: 200% 200%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  animation: vibraniumAnimation 8s linear infinite;
}

.public-profile-badge-card.silver {
  background: linear-gradient(135deg, rgba(192, 192, 192, 0.5) 25%, rgba(128, 128, 128, 0.5) 25%, rgba(192, 192, 192, 0.5) 50%, rgba(128, 128, 128, 0.5) 50%, rgba(192, 192, 192, 0.5) 75%, rgba(128, 128, 128, 0.5) 75%, rgba(192, 192, 192, 0.5));
  background-size: 200% 200%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  animation: silverAnimation 8s linear infinite;
}

.public-profile-badge-card.gold {
  background: linear-gradient(135deg, rgba(255, 215, 0, 0.5) 25%, rgba(184, 134, 11, 0.5) 25%, rgba(255, 215, 0, 0.5) 50%, rgba(184, 134, 11, 0.5) 50%, rgba(255, 215, 0, 0.5) 75%, rgba(184, 134, 11, 0.5) 75%, rgba(255, 215, 0, 0.5));
  background-size: 200% 200%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  animation: goldAnimation 6s linear infinite;
}

.public-profile-badge-card.bronze {
  background: linear-gradient(270deg, rgba(205, 127, 50, 0.5), rgba(139, 69, 19, 0.5), rgba(205, 127, 50, 0.5));
  background-size: 200% 200%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  animation: bronzeFlow 4s ease infinite;
}

/* Language Card Styling */
.language-card-pp {
  background-color: #1b1b2f;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  padding: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 380px; /* Ensure the cards don't overlap */
  cursor: pointer;
  transition: transform 0.3s;
}

.language-card-pp:hover {
  transform: scale(1.05);
}



/* Button Styling */
.acc-button-pp {
  padding: 15px 30px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  background-color: #6c63ff;
  border: 2px solid #6c63ff;
  width: 300px;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s, border-color 0.3s;
}

.acc-button-pp:hover {
  background-color: rgba(15, 14, 23, 0.8);
  color: #ffffff;
  border-color: #ffffff;
  box-shadow: 0 15px 30px rgba(15, 14, 23, 0.4);
  transform: translateY(-3px);
}

.acc-button-pp:active {
  transform: translateY(0);
  box-shadow: 0 10px 20px rgba(15, 14, 23, 0.2);
}

.no-content-container {
  display: flex;
  align-items: center;
}

/* No Languages Text */
.no-languages-text {
  text-align: center;
  color: #ccc;
  font-size: 1.2em;
  margin: 0px;
}

/* No Badges Text */
.no-badges-text {
  text-align: center;
  color: #ccc;
  font-size: 1.2em;
  margin: 0px;
}

.profile-info {
  display: flex;
  flex-direction: column;
}

.profile-info h1 {
  margin-bottom: 10px; /* Reduce space between h1 and the next element */
}

.friend-status-text {
  color: #eaeaea;
  font-size: 1.2em;
  margin: 5px 0;
}

/* Add these styles to your PublicProfile.css file */

.profile-details {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #333;
}

.profile-info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.profile-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}



.action-button,
.settings-button,
.acc-button-pp {
  height: 36px; /* Set a fixed height for all buttons */
  padding: 0 16px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  background-color: #6c63ff;
  border: none;
  border-radius: 18px; /* Half of the height for rounded corners */
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 0;
  white-space: nowrap;
}

.action-button:hover,
.settings-button:hover,
.acc-button-pp:hover {
  background-color: #5a52cc;
  box-shadow: 0 5px 15px rgba(108, 99, 255, 0.4);
  transform: translateY(-2px);
}

.settings-button {
  width: 36px;
  padding: 0;
  border-radius: 50%;
}

/* Remove any width constraints */
.acc-button-pp,
.action-button {
  width: auto;
}

/* Media Queries */
@media (max-width: 768px) {
  .profile-details {
    flex-direction: column;
    align-items: flex-start;
  }

  .profile-actions {
    margin-top: 10px;
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .action-button,
  .settings-button,
  .acc-button-pp {
    flex: 0 1 auto;
  }
}

.action-buttons-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.back-button {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #6c63ff;
  color: #ffffff;
  border: none;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
}

.back-button:hover {
  background-color: #5a52cc;
  box-shadow: 0 5px 15px rgba(108, 99, 255, 0.4);
  transform: translateY(-2px);
}

/* Remove the absolute positioning styles */
@media (max-width: 768px) {
  .action-buttons-group {
    width: 100%;
    justify-content: center;
  }
}

.profile-back-go-back {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #6c63ff;
  color: #ffffff;
  border: none;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
  padding: 0px 0px;
  margin-top: 0px
}

.profile-back-go-back:hover {
  background-color: #5a52cc;
  box-shadow: 0 5px 15px rgba(108, 99, 255, 0.4);
  transform: translateY(-2px);
}

/* Desktop profile actions styling */
.profile-actions {
  display: flex;
  flex-direction: row; /* Changed from column to row */
  align-items: center; /* Center items vertically */
  gap: 10px;
  flex-wrap: nowrap; /* Prevent wrapping */
}

/* Media query for mid-sized devices */
@media (max-width: 1224px) {
  .profile-actions {
    width: 100%;
    flex-direction: row; /* Keep as row */
    justify-content: center; /* Center items horizontally */
    align-items: center;
    gap: 10px;
    margin-top: 20px;
  }

  /* Remove width constraints on buttons */
  .review-request-button,
  .action-button,
  .acc-button-pp {
    width: auto;
    max-width: none;
  }
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .profile-actions {
    flex-wrap: wrap; /* Allow wrapping on very small screens */
    justify-content: center;
    gap: 10px;
  }
}

.friend-request-received {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: auto;
}

.review-request-button {
  height: 36px;
  padding: 0 16px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  background-color: #6c63ff;
  border: none;
  border-radius: 18px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  white-space: nowrap;
}

.review-request-button:hover {
  background-color: #5a52cc;
  box-shadow: 0 5px 15px rgba(108, 99, 255, 0.4);
  transform: translateY(-2px);
}

.friend-status-text {
  color: #eaeaea;
  font-size: 14px;
  margin: 0;
  text-align: left;
}

/* Media queries for mobile responsiveness */
@media (max-width: 768px) {
  .friend-request-received {
    width: 100%;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 10px; /* Add space between request and chat button */
  }

  .profile-actions {
    width: 100%;
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
    gap: 15px;
  }

  .friend-status-text {
    text-align: center;
    width: 100%;
    margin-bottom: 5px;
  }

  .review-request-button {
    width: 100%;
    max-width: 300px;
    margin-bottom: 5px;
  }

  .action-button {
    width: 100%;
    max-width: 300px;
  }

  /* Container for chat button */
  .chat-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5px;
  }
}

/* Additional adjustments for very small screens */
@media (max-width: 480px) {
  .profile-actions {
    gap: 10px;
  }

  .review-request-button,
  .action-button {
    font-size: 13px;
    height: 32px;
    max-width: 250px;
  }
}

/* Base profile actions styling */
.profile-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Media query for mid-sized devices */
@media (max-width: 1224px) {
  .profile-actions {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
  }

  .friend-request-received {
    width: 100%;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 10px;
  }

  .friend-status-text {
    text-align: center;
    width: 100%;
    margin-bottom: 5px;
  }

  .review-request-button,
  .action-button,
  .acc-button-pp {
    width: 100%;
    max-width: 300px;
  }

  .chat-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5px;
  }

  /* Settings and back button container */
  .action-buttons-group {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }

  /* Keep settings and back buttons small and circular */
  .settings-button,
  .profile-back-go-back {
    width: 36px;
    height: 36px;
  }
}

/* Additional adjustments for mobile devices */
@media (max-width: 768px) {
  .profile-actions {
    gap: 12px;
  }

  .review-request-button,
  .action-button,
  .acc-button-pp {
    font-size: 13px;
    height: 32px;
    max-width: 250px;
  }
}

/* Very small screens */
@media (max-width: 480px) {
  .profile-actions {
    gap: 10px;
  }

  .review-request-button,
  .action-button,
  .acc-button-pp {
    max-width: 250px;
  }
}

/* Desktop profile actions styling */
.profile-actions {
  display: flex;
  flex-direction: row; /* Changed from column to row */
  align-items: center; /* Center items vertically */
  gap: 10px;
  flex-wrap: nowrap; /* Prevent wrapping */
}

/* Media query for mid-sized devices */
@media (max-width: 1224px) {
  .profile-actions {
    width: 100%;
    flex-direction: row; /* Keep as row */
    justify-content: center; /* Center items horizontally */
    align-items: center;
    gap: 10px;
    margin-top: 20px;
  }

  /* Remove width constraints on buttons */
  .review-request-button,
  .action-button,
  .acc-button-pp {
    width: auto;
    max-width: none;
  }
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .profile-actions {
    flex-wrap: wrap; /* Allow wrapping on very small screens */
    justify-content: center;
    gap: 10px;
  }
}

/* Friend request container for desktop */
.friend-request-received {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  width: auto;
}

/* Action buttons container for desktop */
.action-buttons-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
}

/* Chat button container */
.chat-button-container {
  display: flex;
  align-items: center;
}

/* Button styles */
.action-button,
.review-request-button,
.settings-button,
.profile-back-go-back {
  height: 36px;
  padding: 0 16px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  background-color: #6c63ff;
  border: none;
  border-radius: 18px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  white-space: nowrap;
}

/* Circular buttons */
.settings-button,
.profile-back-go-back {
  width: 36px;
  padding: 0;
  border-radius: 50%;
}

/* Add this after the .profile-info h1 styles */
.last-online-status {
  color: #a8a8b3;
  font-size: 0.9em;
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 6px;
  background: none; /* Ensure no background */
}

.last-online-status .dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #45b37f; /* Green for recent activity */
  flex-shrink: 0; /* Prevent dot from shrinking */
}

.last-online-status.offline .dot {
  background-color: #9e9e9e; /* Grey for offline */
}

/* Add this new container style */
.profile-actions-row {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 10px;
}

/* Update existing styles */
.profile-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.last-online-status {
  color: #a8a8b3;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-left: auto; /* This will push it to the right */
}

/* Rest of the styles remain the same */
.last-online-status .dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #45b37f;
  flex-shrink: 0;
}

.last-online-status.offline .dot {
  background-color: #9e9e9e;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .profile-actions-row {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .last-online-status {
    margin-left: 0; /* Remove auto margin on mobile */
    margin-top: 5px; /* Add some space between actions and status */
  }
}