.video-chat {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 80vw;
  box-sizing: border-box;
  position: relative;
}

.video-container {
  display: flex;
  justify-content: space-between; /* Space videos evenly */
  margin-bottom: 20px;
  gap: 20px;
  width: 100%;
  position: relative;
}

.local-video-wrapper,
.remote-video-wrapper {
  position: relative;
  width: 48%; /* Adjust as needed */
  overflow: hidden; /* This will ensure the video doesn't overflow */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.local-video,
.remote-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.call-info {
  margin-top: 20px;
  text-align: center;
}

.call-info p {
  margin: 5px 0;
}

.status-connected {
  color: green;
  font-weight: bold;
}

.status-connecting {
  color: orange;
  font-weight: bold;
}

.waiting-message {
  position: absolute; /* Position absolutely within the wrapper */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(27, 27, 47, 0.8); /* Semi-transparent background */
  color: white;
  border-radius: 10px;
  font-size: 1em;
  z-index: 5; /* Ensure it overlays the video */
}

.friend-card-connect {
  background-color: rgba(27, 27, 47, 0.8);
  border-radius: 15px;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  max-width: 200px; /* Fixed width */
  z-index: 10; /* Ensure it appears above the video */
  height: 40px; /* Desired height */
  position: absolute; /* Position absolutely within the video wrapper */
}

.remote-user-info.friend-card-connect {
  top: 10px; /* Distance from the top of the remote video */
  left: 10px; /* Distance from the left of the remote video */
}

.local-user-info.friend-card-connect {
  top: 10px; /* Distance from the top of the local video */
  left: 10px; /* Distance from the left of the local video */
}

.friend-pic-connect img,
.friend-placeholder-connect {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  object-fit: cover;
  background-color: #444;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 1.2em;
}

.friend-info-connect h3 {
  margin: 0;
  color: #ffffff;
  font-size: 1em;
}

.friend-icon {
  cursor: pointer;
  color: #ffffff;
  font-size: 0.8em; /* Smaller size */
  margin-left: 5px; /* More to the left */
  transition: color 0.3s ease;
}

.friend-icon:hover {
  color: #4a47a3;
}

.friend-request-button-wrapper {
  margin-top: 10px;
}

.send-friend-request-button {
  background-color: #4a47a3;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.8em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
}

.send-friend-request-button:hover {
  background-color: #3a3793;
}

.friend-request-sent {
  margin-top: 8px;
  color: green;
  font-weight: bold;
}

.is-friend {
  margin-top: 8px;
  color: #555;
  font-weight: bold;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.languages-container-account {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  margin-top: 3px;
}

.language-flag-account {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #4a47a3;
}

.more-languages {
  font-weight: bold;
  color: white;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-top: 1px;
  font-family: 'Montserrat', sans-serif;
}

@media (max-width: 768px) {
  .video-chat {
    width: 95vw;
    padding: 15px;
  }

  .video-container {
    flex-direction: column;
    gap: 15px;
  }

  .local-video-wrapper,
  .remote-video-wrapper {
    width: 100%;
    height: 200px; /* Adjust as needed for smaller screens */
  }

  .waiting-message {
    font-size: 0.9em;
  }

  .friend-card-connect {
    width: 150px;
    height: 35px;
  }

  .friend-pic-connect img,
  .friend-placeholder-connect {
    width: 25px;
    height: 25px;
  }

  .friend-info-connect h3 {
    font-size: 0.9em;
  }

  .friend-icon {
    font-size: 0.7em; /* Smaller size for smaller screens */
    margin-left: 3px; /* More to the left for smaller screens */
  }

  .send-friend-request-button {
    font-size: 0.7em;
    padding: 6px 10px;
  }
}

/* Floating Action Bar */
.action-bar {
  position: absolute;
  bottom: -65px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  border-radius: 40px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.action-button-video {
  background-color: #2196f3;
  color: white;
  border: none;
  padding: 15px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease, background-color 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.action-button-video:hover {
  background-color: #1976d2;
  transform: scale(1.1);
}

.action-button-video:active {
  transform: scale(0.95);
}

/* Leave Call Button */
.leave-call-button {
  background-color: #ff4d4d;
}

.leave-call-button:hover {
  background-color: #e60000;
}

/* Start Call Button */
.start-call-button {
  background-color: #4caf50;
}

.start-call-button:hover {
  background-color: #3e8e41;
}

/* Chat Window (sample) */
.chat-window {
  position: absolute;
  bottom: 100px;
  right: 20px;
  width: 300px;
  height: 400px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.chat-window::before {
  content: "Chat";
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
  text-align: center;
}

.action-button-video.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.action-button-video.muted {
  background-color: #ff4444;
  color: white;
}

.muted-icon {
  color: white;
}

/* Optional: Add a tooltip for disabled state */
.action-button-video.disabled:hover::after {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
}

.video-chat-message-window {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 300px;
  height: 500px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 1000;
}

.video-chat-message-header {
  padding: 15px;
  background-color: #4a47a3;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.video-chat-message-header h3 {
  margin: 0;
  font-size: 1.1em;
}

.video-chat-close-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 5px;
}

.video-chat-messages-container {
  flex-grow: 1;
  padding: 15px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* Remove or adjust scroll-behavior if present */
  /* scroll-behavior: smooth; */
}

/* Optional: Adjust the scroll behavior for smoother scrolling */
.video-chat-messages-container::-webkit-scrollbar {
  width: 8px;
}

.video-chat-messages-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.video-chat-messages-container::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.05);
}

/* Optional: Add a fade effect for older messages */
.video-chat-messages-container::before {
  content: '';
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: 20px;
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  pointer-events: none;
  z-index: 1;
}

.video-chat-message-bubble {
  max-width: 80%;
  padding: 8px 12px;
  border-radius: 12px;
  margin: 2px 0;
}

.video-chat-message-bubble.sent {
  align-self: flex-end;
  background-color: #4a47a3;
  color: white;
}

.video-chat-message-bubble.received {
  align-self: flex-start;
  background-color: #f0f0f0;
  color: #333;
}

.video-chat-message-sender {
  font-size: 0.8em;
  margin-bottom: 4px;
  opacity: 0.8;
}

.video-chat-input-container {
  padding: 15px;
  display: flex;
  gap: 10px;
  background-color: #f8f8f8;
}

.video-chat-message-input {
  flex-grow: 1;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 20px;
  outline: none;
}

.video-chat-send-button {
  background-color: #4a47a3;
  color: white;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.video-chat-send-button:hover {
  background-color: #3a3793;
}

.chat-button-wrapper {
  position: relative;
}

.unread-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #ff4444;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  border: 2px solid rgba(0, 0, 0, 0.5);
  animation: fadeIn 0.3s ease-in;
}

/* Add these styles to your existing VideoChat.css */
.beta-banner {
  background-color: #ff9800;
  color: white;
  text-align: center;
  padding: 10px;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.beta-banner-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.beta-tag {
  background-color: white;
  color: #ff9800;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8em;
  font-weight: bold;
  text-transform: uppercase;
}

.beta-message {
  font-size: 0.9em;
}

@media (max-width: 768px) {
  .beta-banner {
    font-size: 0.8em;
    padding: 8px;
  }
}